import React from 'react';

function Payhip(props) {
	// const link = `https://payhip.com/b/${productID}`

	function handleClick() {
		if (typeof window !== 'undefined') {
			// window.PayhipConfig = {
			// 	enableCart: true,
			// };

			// bundles and courses included here
			if (['mOfny', '45JXP', 'Od6De', 'vgf8J'].includes(props.productID)) {
				// bundle
				window.open(
					`https://payhip.com/order?link=${props.productID}`,
					'_blank'
				);
			} else {
				// single product
				window.open(`https://payhip.com/b/${props.productID}`, '_blank');
			}

			// window.Payhip.Checkout.open({
			//   product: props.productID,
			//   method: "overlay",
			//   message: "Enter your info to complete the purchase",
			// });
		}
	}
	return (
		<button
			className="btn-simple btn-primary-outline box-shadow"
			onClick={handleClick}
		>
			{props.label}
		</button>
	);
}

export default Payhip;
