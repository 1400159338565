import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import ProductItem from '../components/ProductItem';

const Recommended = (props) => {
	const allEdges = [
		...props.data.allShopJson.edges,
		...props.data.allCoursesJson.edges,
	];

	const filteredEdges = allEdges.filter(
		(data) => props.recommended.indexOf(data.node.detailPagePath) !== -1
	);
	return (
		<>
		<div className="container-fluid bg-white">
			<div className="container col-md-8 pt-10 pb-10 justify-content-left">
						<div className="row mb-2">
							<h2 className="pl-1">Related Books</h2>
						</div>
						<div className="row">
							{filteredEdges.map((edge) => (
								<div className="col-11 col-md-4 mb-md-4 mb-1">
									<ProductItem props={edge}></ProductItem>
								</div>
							))}
					</div>
			</div>
			</div>
		</>
	);
};

export default (props) => (
	<StaticQuery
		query={graphql`
			query {
				allCoursesJson {
					edges {
						node {
							coursePath
							detailPagePath
							extraInfo
							price
							type
							purchaseLinks {
								label
								link
								isOutbound
							}
						}
					}
				}

				allShopJson {
					edges {
						node {
							title
							detailPagePath
							description
							image
							price
						}
					}
				}
			}
		`}
		render={(data) => (
			<Recommended recommended={props.recommended} data={data} />
		)}
	/>
);
